import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import stroke from "../images/stroke-1.png"
import buttonIcon from "../images/button-icon.png"
import buttonIcon2x from "../images/button-icon2x.png"
import Carousel from '../components/carousel.js';
import CarouselTemoignages from '../components/carouselTemoignages.js';


const Title = styled.h1`
  text-align: center;
  background: url(${stroke}) top left repeat-x, 
  url(${stroke}) bottom left repeat-x;
  padding: 27px;
  line-height: 2.56rem;
  margin-bottom: 42px;
  @media (min-width: 576px) {
    margin-bottom: 122px;
  }
`

const Content = styled.div`
  h1{
    line-height: 30px;
    font-size: 1.2rem;
    text-align:center;
    @media (min-width: 576px) {
      font-size: 1.72rem;
    }
  }
`

const BlocsContainer = styled.section`
  display:flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-top:62px;
  @media (min-width: 830px) {
    flex-direction:row;
    align-items: stretch;
    margin-top:122px;
  }
`

const BlocContainer = styled.article`
  max-width: ${props => props.maxWidth}px;
  width: 100%;
  display:flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0 0 22px -6px rgba(0,0,0,0.23);
  margin-bottom: 20px;
  align-items: center;
`

const BlocTitle = styled.div`
  display:flex;
  justify-content: center;
  color: #fff;
  background: ${props => props.bgColor};
  width: 100%;
  h2{
    text-transform: uppercase;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 0.95rem;
    @media (min-width: 576px) {
      font-size: 1.05rem;
    }
  }
`

const BlocContent = styled.div`
  display:flex;
  max-width: 380px;
  flex-direction: column;
  padding: 30px;
  img{
    max-width:100%;
  }
`

const BlocParagraph = styled.div`
  p{
    font-size: ${props => props.fontSize}rem;
    line-height: ${props => props.lineHeight}px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  margin-top: ${props => props.marginTop}px;
  margin-bottom: ${props => props.marginBottom}px;
`

const BlocButton = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;
`

const Button = styled.button`
  background-image: linear-gradient(180deg, #F7F7F7 6%, #D9D9D9 100%);
  border-radius: 30px;
  border:none;
  font-size: 0.95rem;
  color: #919191;
  padding: 11px 24px;
  padding-right: 44px;
  width:100%;
  text-align:left;
  @media (min-width: 576px) {
    padding-right: 24px;
  }
  @media (max-width: 364px) {
    padding-right: 80px;
  }
  cursor: pointer;
`

const ImageButton = styled.img`
  position: absolute;
  right: 0;
  max-width:73px;
`

const CarouselContainer = styled.div`
  margin-top:130px;
  margin-bottom:145px;
`

const CutsomLink = styled.a`
  background:#436FB4;
  color:#fff;
  font-size: 0.8rem;
  padding: 10px 10px;
`

const CutsomLinkContainer = styled.div`
  display:flex;
  justify-content:flex-end;
  padding-top: 25px;
`

const TemoignagesLinkContainer = styled.div`
  display:flex;
  justify-content:flex-end;
  padding-top: 25px;
`

const IndexPage = ({ data: { prismicLandingPage, prismicCarousel, allPrismicTemoignage } }) => {
  let content = prismicLandingPage.data.titre.text;

  const greenWord = 'célébrer';
  const indexOfGreenWord = content.indexOf(greenWord);
  if (indexOfGreenWord >= 0) { 
    content = content.substring(0,indexOfGreenWord) + "<span style='color:#A9C333;'>" + content.substring(indexOfGreenWord,indexOfGreenWord+greenWord.length) + "</span>" + content.substring(indexOfGreenWord + greenWord.length);
  }

  const the150Word = '150ème';
  const indexOfthe150Word = content.indexOf(the150Word);
  if (indexOfthe150Word >= 0) { 
    content = content.substring(0,indexOfthe150Word) + "<span style='color:#C40079;'>" + content.substring(indexOfthe150Word,indexOfthe150Word+the150Word.length) + "</span>" + content.substring(indexOfthe150Word + the150Word.length);
  }

  const hospitalWord = 'Hôpital de Morges!';
  const indexOfhospitalWord = content.indexOf(hospitalWord);
  if (indexOfhospitalWord >= 0) { 
    content = content.substring(0,indexOfhospitalWord) + "<span style='color:#E7B040;'>" + content.substring(indexOfhospitalWord,indexOfhospitalWord+hospitalWord.length) + "</span>" + content.substring(indexOfhospitalWord + hospitalWord.length);
  }

  let storyBlocVisible = true;
  if(prismicLandingPage.data.visibility_bloc_histoire === 'non'){
    storyBlocVisible = false;
  }

  let programBlocVisible = true;
  if(prismicLandingPage.data.visibility_bloc_programme === 'non'){
    programBlocVisible = false;
  }

  let maxBlocContainerWidth = 1000;
  if(programBlocVisible && storyBlocVisible){
    maxBlocContainerWidth = 380;
  }

  let carouselBlocVisible = true;
  if(prismicCarousel.data.carousel_visibility === 'non'){
    carouselBlocVisible = false;
  }

  return(
    <Layout>
      <SEO title="Home" />
      <Title dangerouslySetInnerHTML={{ __html: content }} />
      <Content dangerouslySetInnerHTML={{ __html: prismicLandingPage.data.description.html }} />
      <BlocsContainer>
        {programBlocVisible ? (
          <BlocContainer maxWidth={maxBlocContainerWidth}>
            <BlocTitle bgColor='#A9C333' ><h2>{prismicLandingPage.data.titre1_bloc_programme.text}</h2></BlocTitle>
            <BlocContent>
              <img src={prismicLandingPage.data.programme.url}/>
            </BlocContent>
          </BlocContainer>
          ) : (
            null
          )
        }
        {storyBlocVisible ? (
          <BlocContainer maxWidth={maxBlocContainerWidth}>
            <BlocTitle bgColor='#436FB4'><h2>{prismicLandingPage.data.titre2_bloc_histoire.text}</h2></BlocTitle>
            <BlocContent>
              <BlocParagraph fontSize={0.72} marginTop={10} marginBottom={10} lineHeight={18} dangerouslySetInnerHTML={{ __html: prismicLandingPage.data.paragraphe_section_1_bloc_histoire.html }}></BlocParagraph>
              <BlocParagraph fontSize={1.1} marginTop={10} marginBottom={10} lineHeight={27} dangerouslySetInnerHTML={{ __html: prismicLandingPage.data.paragraphe_section_2_bloc_histoire.html }}></BlocParagraph>
              <CutsomLinkContainer>
                <CutsomLink href="#" onClick={(e) => {e.preventDefault();document.location.href = 'mailto:'+prismicLandingPage.data.mailto_bloc_histoire;}}>{prismicLandingPage.data.text_bouton_bloc_histoire}</CutsomLink>
              </CutsomLinkContainer>
            </BlocContent>
          </BlocContainer>
          ) : (
            null
          )
        }
      </BlocsContainer>
      {carouselBlocVisible ? (
        <CarouselContainer>
          <Content style={{marginBottom:'40px'}} dangerouslySetInnerHTML={{ __html: prismicCarousel.data.carousel_text.html }} />
          <Carousel data={prismicCarousel.data.body} />
        </CarouselContainer>
        ) : (
          null
        )
      }
      <CarouselTemoignages data={allPrismicTemoignage}></CarouselTemoignages>
      <TemoignagesLinkContainer>
        <CutsomLink href="/temoignages">Voir tous les témoignages</CutsomLink>
      </TemoignagesLinkContainer>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    prismicLandingPage {
      data {
        titre {
          text
        }
        description {
          html
        }
        titre1_bloc_programme {
          text
        }
        programme {
          alt
          copyright
          url
        }
        titre2_bloc_histoire{
          text
        }
        paragraphe_section_1_bloc_histoire{
          html
        }
        paragraphe_section_2_bloc_histoire{
          html
        }
        text_bouton_bloc_histoire
        mailto_bloc_histoire
        visibility_bloc_programme
        visibility_bloc_histoire
      }
    },
    prismicCarousel{
      data{
        carousel_text{
          html
        }
        carousel_visibility
        body{
          __typename
          ... on Node{
            ... on PrismicCarouselBodyVideo{
              prismicId
              primary{
                dummyindex
                carousel_youtube_id
                carousel_video_cover_image {
                  alt
                  copyright
                  url
                }
              }
            }
            ... on PrismicCarouselBodyImage{
              prismicId
              primary{
                dummyindex
                carousel_image {
                  alt
                  copyright
                  url
                }
              }
            }
          }
        }
      }
    }
    allPrismicTemoignage(sort:{
      fields:[last_publication_date],
      order: DESC
    }){
      edges {
        node {
          data{
            home_page
            titre {
              html
              text
            }
            chapeau {
              html
              text
            }
            contenu {
              html
              text
            }
            image {
              alt
              copyright
              url
            }
          }
        }
      }
    }
  }
`
