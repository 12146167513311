import React from "react"
import styled from "styled-components"
import FooterFill from "../images/footer-fill.png"
import LinkedinImg from "../images/facebook.png"
import FacebookImg from "../images/linkedin.png"
import FacebookImg2x from "../images/facebook2x.png"
import LinkedinImg2x from "../images/linkedin2x.png"
import EhcLogo from "../images/ehc-logo.png"
import { StaticQuery, graphql } from "gatsby"

const FooterContainer = styled.footer`
  background: url(${FooterFill}) top left repeat-x, 
  url(${FooterFill}) bottom left repeat-x;
  height: 87px;
  color:#fff;
  font-size:0.72rem;
  display:flex;
`

const ContentContainer = styled.div`
  max-width: ${props => props.maxWidth}px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 1.0875rem;
  display:flex;
  align-items: center;
  justify-content: space-between;
`

const SocialContainer = styled.div`
  a{
    img{
      max-height: 18px;
    }
    &:last-child{
      margin-left: 28px;
    }
  }
`

const LinkContainer = styled.div``

const Footer = ({maxWidth}) => (
  <StaticQuery
    query={graphql`
      query SiteUrlQuery {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `}
    render={data => (
      <FooterContainer>
        <ContentContainer maxWidth={maxWidth}>
          <LinkContainer>
            <a target='_blank' href="https://www.ehc-vd.ch/hopital-morges"><img src={EhcLogo} style={{maxWidth: `230px`}} alt='Logo EHC' /></a>
          </LinkContainer>
          <SocialContainer>
            <a target='_blank' href="https://www.facebook.com/EnsembleHospitalierdelaCote"><img style={{maxWidth: `245px`}} src={FacebookImg} alt="Facebook" srcSet={FacebookImg2x} /></a>
            <a target='_blank' href="https://www.linkedin.com/company/ensemble-hospitalier-de-la-c-te"><img style={{maxWidth: `245px`}} src={LinkedinImg} alt="Linkedin" srcSet={LinkedinImg2x} /></a>
          </SocialContainer>
        </ContentContainer>
      </FooterContainer>
    )}
  />
)

export default Footer