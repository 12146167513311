/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import styled from "styled-components"
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

import "./normalize.css"
import "./layout.css"

const Main = styled.div`
  margin: 0 auto;
  max-width: 780px;
  padding: 0px 1.0875rem 1.45rem;
  padding-top: 0;
`
const Container = styled.div``

const NewContainer = styled.div``

const Layout = ({ children }) => (
  <ParallaxProvider>
    <NewContainer>
      <Parallax  y={['-100px', '100px']} tagOuter="div">   
        <Container className="parallax">
          <Main>
            <Header/>
            <main>{children}</main>
          </Main>
          <Footer maxWidth={780} />
        </Container>
      </Parallax>
    </NewContainer>
  </ParallaxProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
