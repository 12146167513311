import React from "react"
import styled from "styled-components"
import stroke from "../images/stroke-1.png"

const Title = styled.div`
  text-align: center;
  background: url(${stroke}) top left repeat-x, 
  url(${stroke}) bottom left repeat-x;
  padding: 27px;
  line-height: 2rem;
  margin-bottom: 42px;
  p,h1,h2,h3,h4,h5{
    margin:0.2rem;
  }
  @media (min-width: 576px) {
    margin-bottom: 122px;
  }
`

const PageTitle = ({content}) => {
  return(
    <Title dangerouslySetInnerHTML={{ __html: content }} />
    )
}

export default PageTitle