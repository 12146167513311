import React from "react"
import styled from "styled-components"

const TemoignageModalImage = styled.div`
  background-image: url('${props => props.image}');
  background-size: cover;
  background-position: center;
  height: 290px;
  width: 100%;
`

const TemoignageModalTitle = styled.div`
  margin:auto;
  h1{
    text-align:center;
    color:#fff;
    margin: 0;
    text-transform: uppercase;
    font-size: 1rem;
    @media (max-width: 576px) {
      font-size: 0.8rem;
    }
  }
`

const TemoignageModalHeader = styled.div`
  background: #E7B040;
  padding: 14px;
  display: flex;
  justify-content: space-between;
  position:sticky;
  top: -1px;
`

const TemoignageModalChapeau = styled.p`
  padding: 0 80px;
  font-size: 0.8rem;
  line-height: 1.3rem;
  font-weight: bold;
  margin-top: 80px;
  margin-bottom: 30px;
  @media (max-width: 576px) {
    margin-top: 30px;
    padding: 0 30px;
    font-size: 0.7rem;
    line-height: 1.2rem;
  }
`
const TemoignageModalContent = styled.p`
  padding: 0 80px;
  font-size: 0.8rem;
  line-height: 1.3rem;
  @media (max-width: 576px) {
    padding: 0 30px;
    font-size: 0.7rem;
    line-height: 1.2rem;
  }
`

const TemoignageModalClosButton = styled.button`
  font-size:1.5rem;
  border: none;
  background: none;
  color:#fff;
  cursor:pointer;
  align-self: flex-start;
`

const TemoignageModal = ({image,titre,chapeau,content,closeModal}) => {

  return(
    <React.Fragment>
      <TemoignageModalHeader>
        <TemoignageModalTitle><h1>{titre}</h1></TemoignageModalTitle>
        <TemoignageModalClosButton onClick={(e) => {e.preventDefault();closeModal()}} >✕</TemoignageModalClosButton>
      </TemoignageModalHeader>
      <TemoignageModalImage image={image}/>
      <TemoignageModalChapeau dangerouslySetInnerHTML={{ __html: chapeau }}></TemoignageModalChapeau>
      <TemoignageModalContent dangerouslySetInnerHTML={{ __html: content }}></TemoignageModalContent>
    </React.Fragment>
  )
}


export default TemoignageModal;