import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo150ans from "../images/logo-150-ans.png"
import Logo150ans2x from "../images/logo-150-ans-2x.png"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const HeaderContainer = styled.header`
  margin-bottom: 80px;
  @media (min-width: 576px) {
    margin-bottom: 220px;
  }
`

const Header = () => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <HeaderContainer>
        <img style={{maxWidth: `245px`}} src={Logo150ans} alt={data.site.siteMetadata.title} srcSet={`${Logo150ans2x} 2x`} />
      </HeaderContainer>
    )}
  />
)

export default Header
