import React, {useState} from "react"

import styled from "styled-components"
import { CarouselProvider, Slider, Slide, ButtonNext, ButtonBack } from 'pure-react-carousel';
import SliderExample from "../images/slider-example.png"
import 'pure-react-carousel/dist/react-carousel.es.css';
import "./carousel.css"
import TemoignageModal from '../components/temoignageModal.js';
import Modal from 'react-modal';
import { useMediaQuery } from 'react-responsive'

const TemoignagesCarouselTitle = styled.div`
  margin:auto;
  h1{
    text-align:center;
    color:#fff;
    margin: 0;
    text-transform: uppercase;
    font-size: 1rem;
  }
`

const TemoignagesCarouselHeader = styled.div`
  background: #E7B040;
  padding: 14px;
  display: flex;
  justify-content: space-between;
`
const Temoignage = styled.article`
  display:flex;
  height: 100%;
  @media (max-width: 576px) {
    flex-direction:column;
    height: 100%;
  }
`

const TemoignageImage = styled.div`
  background-image: url('${props => props.image}');
  background-size: cover;
  background-position: center;
  height: auto;
  flex-basis: 50%;
  @media (max-width: 576px) {
    flex-basis: 40%;
    height:40%;
  }
`
const TemoignageContent = styled.div`
  flex-basis: 50%;
  button{
    cursor:pointer;
    color: #fff;
    font-size: 0.6rem;
    background: #E7B040;
    padding: 3px 15px;
    border:none;
    border-radius: 10px;
    line-height: 20px;
    margin: 20px 40px;
    @media (max-width: 768px) {
      margin: 10px 20px;
    }
    @media (max-width: 576px) {
      margin: 5px 10px;
    }
  }
  @media (max-width: 576px) {
    flex-basis: 60%;
    height:60%;
  }
`

const TemoignageContentTitle = styled.p`
  padding: 20px 40px;
  font-weight: bold;
  font-size: 0.8rem;
  line-height: 1.3rem;
  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 0.7rem;
    line-height: 1.2rem;
  }
  @media (max-width: 576px) {
    padding: 5px 10px;
    font-size: 0.7rem;
    line-height: 1.1rem;
  }
`

const TemoignageContentChapeau = styled.p`
  padding: 0 40px;
  font-size: 0.8rem;
  line-height: 1.3rem;
  @media (max-width: 768px) {
    padding: 0px 20px;
    font-size: 0.7rem;
    line-height: 1.2rem;
  }
  @media (max-width: 576px) {
    padding: 0px 10px;
    font-size: 0.7rem;
    line-height: 1.1rem;
  }
`

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 577 })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 341, maxWidth: 576 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 340 })
  return isMobile ? children : null
}

const CarouselTemoignages = ({data}) => {

	let slides = [];
  data.edges.forEach((temoignage,index) => {
    if(temoignage.node.data.home_page == "Oui"){
      slides.push(
        <CarouselTemoignageSlide index={index} key={index} temoignage={temoignage}/>
      )
    }
  })

  const smallCarousel = (
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={150}
            totalSlides={slides.length}
            style={{position:'relative'}}
          >
            <Slider style={{boxShadow: '0 0 22px -6px rgba(0,0,0,0.23)',background: '#fff'}}>
              {slides}
            </Slider>
            <ButtonNext className="btnNext" style={{padding:0 ,background:'transparent', position: 'absolute',top: '45%',right: '-18px',width: 0,height: 0,borderTop: '10px solid transparent',borderBottom: '10px solid transparent',borderLeft: '10px solid #436FB4', borderRight:'none'}}></ButtonNext>
            <ButtonBack className="btnBack" style={{padding:0 ,background:'transparent', position: 'absolute',top: '45%',left: '-18px',width: 0,height: 0,borderTop: '10px solid transparent',borderBottom: '10px solid transparent',borderRight: '10px solid #436FB4', borderLeft:'none'}}></ButtonBack>
          </CarouselProvider>)

  const mediumCarousel = (<CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={110}
            totalSlides={slides.length}
            style={{position:'relative'}}
          >
            <Slider style={{boxShadow: '0 0 22px -6px rgba(0,0,0,0.23)',background: '#fff'}}>
              {slides}
            </Slider>
            <ButtonNext className="btnNext" style={{padding:0 ,background:'transparent', position: 'absolute',top: '45%',right: '-18px',width: 0,height: 0,borderTop: '10px solid transparent',borderBottom: '10px solid transparent',borderLeft: '10px solid #436FB4', borderRight:'none'}}></ButtonNext>
            <ButtonBack className="btnBack" style={{padding:0 ,background:'transparent', position: 'absolute',top: '45%',left: '-18px',width: 0,height: 0,borderTop: '10px solid transparent',borderBottom: '10px solid transparent',borderRight: '10px solid #436FB4', borderLeft:'none'}}></ButtonBack>
          </CarouselProvider>)

  const largeCarousel = (<CarouselProvider
            naturalSlideWidth={780}
            naturalSlideHeight={470}
            totalSlides={slides.length}
            style={{position:'relative'}}
          >
            <Slider style={{boxShadow: '0 0 22px -6px rgba(0,0,0,0.23)',background: '#fff'}}>
              {slides}
            </Slider>
            <ButtonNext className="btnNext" style={{padding:0 ,background:'transparent', position: 'absolute',top: '45%',right: '-18px',width: 0,height: 0,borderTop: '10px solid transparent',borderBottom: '10px solid transparent',borderLeft: '10px solid #436FB4', borderRight:'none'}}></ButtonNext>
            <ButtonBack className="btnBack" style={{padding:0 ,background:'transparent', position: 'absolute',top: '45%',left: '-18px',width: 0,height: 0,borderTop: '10px solid transparent',borderBottom: '10px solid transparent',borderRight: '10px solid #436FB4', borderLeft:'none'}}></ButtonBack>
          </CarouselProvider>
    )

  return(
    <div>
      <TemoignagesCarouselHeader>
        <TemoignagesCarouselTitle><h1>Témoignages</h1></TemoignagesCarouselTitle>
      </TemoignagesCarouselHeader>
      <Desktop>{largeCarousel}</Desktop>
      <Tablet>{mediumCarousel}</Tablet>
      <Mobile>{smallCarousel}</Mobile>
      
    </div>
  )
}

const CarouselTemoignageSlide = ({temoignage}) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false) 
  }

  return(
    <Slide>
      <Temoignage>
        <TemoignageImage image={temoignage.node.data.image.url} alt={temoignage.node.data.image.alt}/>
        <TemoignageContent>
          <TemoignageContentTitle>{temoignage.node.data.titre.text}</TemoignageContentTitle>
          <TemoignageContentChapeau dangerouslySetInnerHTML={{ __html: temoignage.node.data.chapeau.html }}></TemoignageContentChapeau>
          <button onClick={() => openModal()}>Lire plus</button>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            style={{overlay: {backgroundColor: 'rgba(0, 0, 0, 0.75)'},content:{maxWidth:'780px',margin:'auto',padding:0,border:'none'}}}
          ><TemoignageModal closeModal={closeModal} image={temoignage.node.data.image.url} titre={temoignage.node.data.titre.text} chapeau={temoignage.node.data.chapeau.html} content={temoignage.node.data.contenu.html} />
          </Modal>
        </TemoignageContent>
      </Temoignage>
    </Slide>
  ) 
}

export default CarouselTemoignages;