import React from "react"

import styled from "styled-components"
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import YouTube from 'react-youtube';
import SliderExample from "../images/slider-example.png"
import 'pure-react-carousel/dist/react-carousel.es.css';
import "./carousel.css"


const Carousel = ({data}) => {

	let slides = [];
	data.forEach((slide,index) => {
    if(slide.primary.dummyindex != "invisible"){
  		if(slide.__typename === 'PrismicCarouselBodyVideo'){
  			slides.push(
  				<Slide index={index} key={slide.prismicId}>
  	      	<YoutubeSlide id={slide.primary.carousel_youtube_id} cover={slide.primary.carousel_video_cover_image} />
  	      </Slide>
        )
  		}else{
  			slides.push(
  				<Slide index={index} key={slide.prismicId}>
  			    <img src={slide.primary.carousel_image.url} alt={slide.primary.carousel_image.url}/>
  			  </Slide>
  	    )
  		}
    }
	})

  return(
    <CarouselProvider
      naturalSlideWidth={780}
      naturalSlideHeight={466}
      totalSlides={slides.length}
    >
      <Slider>
        {slides}
      </Slider>
      <DotGroup/>
    </CarouselProvider>
  )
}


const VideoCover = styled.div`
  display: ${props =>
	  (props.visible === false && 'none') || 
	  (props.visible === true && 'block')
	};
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	cursor:pointer;
`
const VideoWrapper = styled.div`
  position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
	overflow: hidden;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`

class YoutubeSlide extends React.Component  {

  constructor(props) {
	  super(props);
	  this.state = { player: null,played:false };
	}

	_onReady(event) {
    this.setState({
    	player: event
    })

  }

  playVideo(){
  	if(this.state.player != null){
  		this.state.player.target.playVideo();
  		this.setState({played:true})
  	}
  }

  render() {
    return (
    	<div>
	      <VideoWrapper>
	      	<YouTube
		        videoId={this.props.id}
		        onReady={this._onReady.bind(this)}
		      />
	    	</VideoWrapper>
	    	<VideoCover visible={!this.state.played} onClick={() => this.playVideo()}>
	        <img src={this.props.cover.url} alt={this.props.cover.alt}/>
		    </VideoCover>
	    </div>
    )
  }
}

export default Carousel;