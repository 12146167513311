import React, { useState } from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import PageTitle from "../components/pageTitle"
import SEO from "../components/seo"
import Modal from 'react-modal';
import TemoignageModal from '../components/temoignageModal.js';

Modal.setAppElement('#___gatsby');

const CustomLink = styled.a`
  background:#436FB4;
  color:#fff;
  font-size: 0.8rem;
  padding: 10px 10px;
`

const TemoignagesContainer = styled.section`
  display:flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const TemoignagesPage = ({data}) => {

  const temoignagesData = (data.allPrismicTemoignage.edges);
  const temoignages = [];
  temoignagesData.forEach((temoignage,index) => {
    temoignages.push(
      <Temoignage key={index} image={temoignage.node.data.image.url} titre={temoignage.node.data.titre.text} chapeau={temoignage.node.data.chapeau.html} content={temoignage.node.data.contenu.html}/>
    )
  })

  return(
    <Layout>
      <SEO title="Home" />
      <PageTitle content={data.prismicTemoignagesPage.data.titre.html}/>
      <TemoignagesContainer>
        {temoignages}
      </TemoignagesContainer>
      <CustomLink href="/">Retour à l'accueil</CustomLink>
    </Layout>
  )
}

const TemoignageContainer = styled.article`
  display:flex;
  flex-direction: column;
  flex-basis: 48%;
  margin-bottom: 20px;
  @media (max-width: 576px) {
    flex-basis: 100%;
  }
`

const TemoignageContainerFooter = styled.div`
  height: 45px;
  background: #E7B040;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  h1{
    font-size: 0.8rem;
    line-height: 1.2;
    padding-right: 5px;
  }
  button{
    cursor:pointer;
    color: #436FB4;
    font-size: 0.6rem;
    background: #fff;
    padding: 0px 12px;
    border:none;
    border-radius: 9px;
    line-height: 20px;
    margin: 0;
  }
`

const TemoignageContainerImage = styled.div`
  background-image: url('${props => props.image}');
  background-size: cover;
  background-position: center;
  height: 180px;
  width: 100%;
`

const Temoignage = ({image,titre,chapeau,content}) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false) 
  }

  return(
    <TemoignageContainer>
      <TemoignageContainerImage image={image}/>
      <TemoignageContainerFooter>
        <h1>{titre}</h1>
        <button onClick={() => openModal()}>Découvrir</button>
      </TemoignageContainerFooter>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={{overlay: {backgroundColor: 'rgba(0, 0, 0, 0.75)'},content:{maxWidth:'780px',margin:'auto',padding:0,border:'none'}}}
      >
        <TemoignageModal closeModal={closeModal} image={image} titre={titre} chapeau={chapeau} content={content} />
      </Modal>
    </TemoignageContainer>
  )
}



export default TemoignagesPage

export const pageQuery = graphql`
  query {
    allPrismicTemoignage(sort:{
      fields:[last_publication_date],
      order: DESC
    }){
      edges {
        node {
          data{
            titre {
              html
              text
            }
            chapeau {
              html
              text
            }
            contenu {
              html
              text
            }
            image {
              alt
              copyright
              url
            }
          }
        }
      }
    }
    prismicTemoignagesPage{
      data{
        titre {
          html
          text
        }
      }
    }
  }
`
